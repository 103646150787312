{
  "name": "grc-frontend",
  "version": "0.1.1-186",
  "engines": {
    "node": "^20.9.0"
  },
  "scripts": {
    "ng": "ng",
    "start": "ng serve --configuration development",
    "start:qa": "ng serve --configuration qa",
    "start:prod": "ng serve --configuration prod",
    "start:staging": "ng serve --configuration staging",
    "build": "ng build",
    "build:development": "ng build --configuration development",
    "build:qa": "ng build --configuration qa",
    "build:production": "ng build --configuration production",
    "build:staging": "ng build --configuration staging",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "lint": "eslint 'src/**/*.ts'",
    "lint:fix": "eslint --fix 'src/**/*.ts'",
    "format": "prettier --write 'src/**/*.ts' 'src/**/*.html' 'src/**/*.less'"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.1.0",
    "@angular/common": "^18.1.0",
    "@angular/compiler": "^18.1.0",
    "@angular/core": "^18.1.0",
    "@angular/forms": "^18.1.0",
    "@angular/platform-browser": "^18.1.0",
    "@angular/platform-browser-dynamic": "^18.1.0",
    "@angular/router": "^18.1.0",
    "@tinymce/tinymce-angular": "^8.0.0",
    "@types/googlemaps": "^3.43.3",
    "ng-zorro-antd": "^18.0.1",
    "ng2-pdf-viewer": "^10.3.4",
    "rxjs": "~7.8.0",
    "tinymce": "^7.5.1",
    "tslib": "^2.6.3",
    "xlsx": "^0.18.5",
    "zone.js": "^0.14.7"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.1.0",
    "@angular-eslint/builder": "^18.1.0",
    "@angular-eslint/eslint-plugin": "^18.1.0",
    "@angular-eslint/eslint-plugin-template": "^18.1.0",
    "@angular-eslint/schematics": "^18.1.0",
    "@angular-eslint/template-parser": "^18.1.0",
    "@angular/cli": "^18.1.0",
    "@angular/compiler-cli": "^18.1.0",
    "@angular/localize": "^18.1.0",
    "@types/jasmine": "~5.1.0",
    "@types/tinymce": "^4.6.9",
    "@typescript-eslint/eslint-plugin": "^7.15.0",
    "@typescript-eslint/parser": "^7.15.0",
    "eslint": "^9.6.0",
    "jasmine-core": "^5.1.2",
    "karma": "^6.4.3",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "prettier": "^3.3.2",
    "typescript": "^5.5.3"
  }
}
