import { Routes, mapToCanActivate } from '@angular/router';
import { AppGuard } from './guards/app.guard';
import { AuthGuard } from './guards/auth.guard';
import { SignUpGuard } from './guards/signup.guard';
import { APP_ROUTES } from './routes';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: APP_ROUTES.DASHBOARD.PATH
  },
  {
    path: APP_ROUTES.LOGIN.PATH,
    loadComponent: () => import('./pages/login/login.component').then(m => m.LoginComponent)
  },
  {
    path: APP_ROUTES.SSO.PATH,
    loadComponent: () => import('./pages/login/login.component').then(m => m.LoginComponent)
  },
  {
    path: APP_ROUTES.SIGN_UP.PATH,
    canActivate: mapToCanActivate([SignUpGuard]),
    loadComponent: () => import('./pages/sign-up/sign-up.component').then(m => m.SignUpComponent)
  },
  {
    path: APP_ROUTES.ACCESS_CODE.PATH,
    loadComponent: () => import('./pages/access-code/access-code.component').then(m => m.AccessCodeComponent)
  },
  {
    path: APP_ROUTES.RESET_PASSWORD.PATH,
    loadComponent: () => import('./pages/reset-password/reset-password.component').then(m => m.ResetPasswordComponent)
  },
  {
    path: APP_ROUTES.FORGOT_PASSWORD.PATH,
    loadComponent: () =>
      import('./pages/forgot-password/forgot-password.component').then(m => m.ForgotPasswordComponent)
  },
  {
    path: APP_ROUTES.ACTIVATION.PATH,
    loadComponent: () => import('./pages/activation/activation.component').then(m => m.ActivationComponent)
  },
  {
    path: APP_ROUTES.SPLASH.PATH,
    loadComponent: () => import('./pages/splash/splash.component').then(m => m.SplashComponent)
  },
  {
    path: APP_ROUTES.TOS.PATH,
    loadComponent: () => import('./pages/legal/tos/tos.component').then(m => m.tosComponent)
  },
  {
    path: APP_ROUTES.PP.PATH,
    loadComponent: () => import('./pages/legal/pp/pp.component').then(m => m.ppComponent)
  },
  {
    path: APP_ROUTES.TOU.PATH,
    loadComponent: () => import('./pages/legal/tou/tou.component').then(m => m.touComponent)
  },
  {
    path: APP_ROUTES.DASHBOARD.PATH,
    canActivate: mapToCanActivate([AuthGuard]),
    loadComponent: () => import('./pages/dashboard/dashboard.component').then(m => m.DashboardComponent),
    children: [
      {
        path: '',
        redirectTo: APP_ROUTES.HOME.PATH,
        pathMatch: 'full'
      },
      {
        path: APP_ROUTES.HOME.PATH,
        canActivate: mapToCanActivate([AuthGuard]),
        loadComponent: () => import('./pages/home/home.component').then(m => m.HomeComponent)
      },
      {
        path: APP_ROUTES.SURVEYS.PATH,
        canActivate: mapToCanActivate([AuthGuard]),
        loadComponent: () => import('./pages/survey/survey.component').then(m => m.SurveyComponent)
      },
      {
        path: APP_ROUTES.PORTFOLIOS.PATH,
        canActivate: mapToCanActivate([AuthGuard]),
        loadComponent: () => import('./pages/portfolio/portfolio.component').then(m => m.PortfolioComponent)
      },
      {
        path: APP_ROUTES.POLICIES.PATH,
        canActivate: mapToCanActivate([AuthGuard]),
        loadComponent: () =>
          import('./pages/policy/policy-dashboard/policy-dashboard.component').then(m => m.PolicyDashboardComponent)
      },
      {
        path: APP_ROUTES.MANAGE_USERS.PATH,
        canActivate: mapToCanActivate([AuthGuard]),
        loadComponent: () => import('./pages/manage-users/manage-users.component').then(m => m.ManageUsersComponent),
        data: { expectedRole: ['admin', 'owner'] }
      },
      {
        path: APP_ROUTES.MANAGE_CONSULTANTS.PATH,
        canActivate: mapToCanActivate([AuthGuard, AppGuard]),
        loadComponent: () =>
          import('./pages/manage-consultants/manage-consultants.component').then(m => m.ManageConsultantsComponent),
        data: { expectedRole: ['admin', 'owner'] }
      },
      {
        path: APP_ROUTES.POLICIES.CHILDREN.DOCUMENT.PATH,
        canActivate: mapToCanActivate([AuthGuard]),
        loadComponent: () =>
          import('./pages/policy/policy-document-editor/policy-document-editor.component').then(
            m => m.PolicyDocumentEditorComponent
          )
      },
      {
        path: APP_ROUTES.SURVEYS.CHILDREN.DOCUMENT.PATH,
        canActivate: mapToCanActivate([AuthGuard]),
        loadComponent: () =>
          import('./pages/survey-questionnaire-editor/survey-questionnaire-editor.component').then(
            m => m.SurveyQuestionnaireEditorComponent
          )
      },
      {
        path: APP_ROUTES.POLICIES.CHILDREN.QUESTIONNAIRE.PATH,
        canActivate: mapToCanActivate([AuthGuard]),
        loadComponent: () =>
          import('./pages/policy/policy-questionnaire/policy-questionnaire.component').then(
            m => m.PolicyQuestionnaireComponent
          )
      },
      {
        path: APP_ROUTES.CONTROLS.PATH,
        canActivate: mapToCanActivate([AuthGuard]),
        loadComponent: () =>
          import('./pages/controls-dashboard/controls-dashboard.component').then(m => m.ControlsDashboardComponent)
      },
      {
        path: APP_ROUTES.CONTROLS.CHILDREN.DOCUMENT.PATH,
        canActivate: mapToCanActivate([AuthGuard]),
        loadComponent: () =>
          import('./pages//control-evidence-editor/control-evidence-editor.component').then(
            m => m.ControlEvidenceEditorComponent
          )
      },
      {
        path: APP_ROUTES.PROFILE.PATH,
        canActivate: mapToCanActivate([AuthGuard]),
        loadComponent: () => import('./pages/my-account/my-account.component').then(m => m.MyAccountComponent)
      },
      {
        path: APP_ROUTES.HELP_FEEDBACK.PATH,
        canActivate: mapToCanActivate([AuthGuard]),
        loadComponent: () => import('./pages/help-feedback/help-feedback.component').then(m => m.HelpFeedbackComponent)
      },
      {
        path: APP_ROUTES.PERMISSIONS.PATH,
        canActivate: mapToCanActivate([AuthGuard]),
        loadComponent: () => import('./pages/permissions/permissions.component').then(m => m.PermissionsComponent),
        data: { expectedRole: ['admin', 'owner'] }
      },
      {
        path: APP_ROUTES.SETTINGS.PATH,
        canActivate: mapToCanActivate([AuthGuard]),
        loadComponent: () =>
          import('./pages/company-settings/company-settings.component').then(m => m.CompanySettingsComponent),
        data: { expectedRole: ['admin', 'owner'] }
      }
    ]
  },
  {
    path: '**',
    loadComponent: () => import('./pages/error/error.component').then(m => m.ErrorComponent)
  }
];
